<template>
  <div>
    <b-img fluid-grow center :src="image" :alt="alt" />
    <b-container>
      <h1 v-if="header">{{header}}</h1>
      <slot></slot>
      <div style="text-align:center">
        <b-button :to="{name: routeName}">{{cta}}</b-button>
      </div>
    </b-container>
  </div>
</template>

<script>
import { BButton, BImg } from 'bootstrap-vue'
export default {
  name: "Hero",
  components: {
    BButton,
    BImg
  },
  props: ["header", "image", "cta", "routeName", "alt"]
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
p, a {
  font-size: 2em;
}
@media only screen and (max-width: 576px) {
  p, a {
    font-size: 1.5em;
  }
}
</style>
